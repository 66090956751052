.side-menu-icon {
  padding-left: 20px;
}

.side-menu-logo-container {
  padding: 20px;
}

.side-menu-logo-container img {
  width: 100%;
}
