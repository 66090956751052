@import './../colors.scss';
@import './../breakpoints.scss';

.normateca-option-container {
  border: 2px solid $orange;
  border-radius: 15px;
  padding: 15px 15px 15px 15%;
  text-decoration: none;
  margin: 0 0 20px 0;
}

.normateca-option-icon-container {
  color: $orange;

  & svg {
    width: 70px;
  }
}

.normateca-option-title {
  margin-left: 50px;
  color: white;
  font-size: 20px;
}

@include media-breakpoint-up(md) {
  .normateca-option-container {
    flex-direction: column;
    padding: 15px;
    justify-content: center;
    align-items: center;
    min-height: 170px;
  }

  .normateca-option-title {
    text-align: center;
    margin: 0;
    margin-top: 5px;
  }
}

@include media-breakpoint-up(lg) {
  .normateca-option-container {
    flex-direction: row;
    padding: 15px 30px;
    justify-content: start;
    align-items: center;
    min-height: 170px;
  }
  .normateca-option-icon-container {
    color: white;
    background-color: $orange;
    padding: 10px 18px;
    border-radius: 15px;
    & svg {
      width: 70px;
    }
  }

  .normateca-option-title {
    text-align: left;
    margin: 20px;
  }
}
