// scss-docs-start color-variables
$blue: #20164d !default;
$indigo: #1f2071 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #f53434 !default;
$orange: #ff784e !default;
$orange-secondary: #ffad4e;
$yellow: #c48f00 !default;
$green: #60bc50 !default;
$teal: #38babf !default;
$cyan: #007bff !default;
$head-foot: #d5dff2 !default;
$blue2: #0057c1;
$blue3: #0a86ea;
$blue4: #0a86ea61;
$blue5: #0a86ea1c;
// scss-docs-end color-variables

$gray-sac1: #e8e8e8;
$gray-sac2: #e5e5e5;
$gray-sac3: #aaa8a8;
