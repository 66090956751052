@import './../colors.scss';

.online-content {
  margin-top: 10%;
}
.no-connection-icon {
  font-size: 100px;
  color: #fb9898;
}
.no-connection-text {
  margin-top: 30px;
  color: $gray-sac3;
  font-weight: bold;
}
