@import '../colors.scss';
@import '../breakpoints.scss';

.procedure-document-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
}

.procedure-document-fileinput {
  flex-grow: 1;
}

.procedure-document-actions,
.procedure-document-icons {
  display: flex;
  gap: 16px;
}

.procedure-document-icons {
  min-width: 180px;
  flex-direction: row;
  align-items: center;
  background-color: $gray-sac1;
  padding: 4px;
  border-radius: 8px;
}

.procedure-document-actions {
  flex-direction: column;
  align-items: start;
  width: 100%;
  & .fa-times-circle {
    color: #c0392b;
  }

  & .fa-check-circle {
    color: #27ae60;
  }
}

.custom-file-input-container img {
  width: 40px;
}

@include media-breakpoint-up('lg') {
  .procedure-document-actions {
    flex-direction: row;
    align-items: center;
  }
}
