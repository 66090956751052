@import '../colors.scss';

.normateca-document-item {
  background-color: #4d3c99;
  border-radius: 10px;
  padding: 4px 16px;
  margin: 16px 0;
  min-height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normateca-document {
  order: 0;
}
.convivencia-document {
  order: 1;
}
.convivencia-document .normateca-document-item {
  background-color: $orange;
}
