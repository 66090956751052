@import './../colors.scss';
@import './../mixins.scss';
$lines-to-show: 2;

.notice-remainder {
  margin-bottom: 5px;
}
.notice-remainder-container {
  border-radius: 10px;
  &.active {
    background-color: $gray-sac1;
  }
}

.notice-remainder-icon {
  border-radius: 10px 0 0 10px;
  padding: 10px 15px;
}

.notice-remainder-icon svg {
  width: 35px;
}

.notice-remainder-option {
  border-radius: 0 10px 10px 0;
  padding: 0 15px;
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
  border-right: 2px solid #e8e8e8;
}

.notice-remainder-text {
  padding: 10px;
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
  flex-grow: 1;
  & .notice-remainder-description {
    @include break-text($lines-to-show);
  }
}
