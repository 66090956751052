@import '../colors.scss';

.pdf-viewer {
  background-color: rgb(88, 88, 88);
  padding: 16px;
  max-height: 100%;
}

.pdf-paginator {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  color: white;
  background-color: rgb(88, 88, 88);

  & .prev,
  & .next {
    color: white;
  }

  & .paginator {
    font-weight: bold;
  }
}

.react-pdf__Document {
  display: flex;
  align-items: center;
  height: calc(100% - 50px) !important;
}

.react-pdf__Page {
  margin: 0 auto;
  max-height: 100% !important;
  overflow: auto;
}
