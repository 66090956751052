@import './../colors.scss';
@import './../breakpoints.scss';

.selected-task-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  border-top: 2px solid $gray-sac1;
  border-bottom: 2px solid $gray-sac1;
  padding: 10px 15px;
}

.selected-task-text {
  margin-left: 15px;
}

.selected-task-icon {
  max-width: 25%;
  & img {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-up('md') {
  .selected-task-container {
    border: 2px solid $gray-sac1;
    border-radius: 10px;
  }
}
