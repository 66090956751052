@import './../colors.scss';
@import './../breakpoints.scss';

.file-description {
  font-size: 12px;
}

@include media-breakpoint-up('md') {
  .documents-container {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 20px;
  }
}
