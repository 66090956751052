@import '../colors.scss';
@import '../breakpoints.scss';

.new-version {
  background-color: $blue;
}

.new-version-container {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  user-select: none !important;
}

.new-version-icon {
  margin-right: 8px;
}

@include media-breakpoint-up('md') {
  .new-version {
    background-color: $orange;
  }
}
