@import './../colors.scss';
@import './../breakpoints.scss';

.shopping-car-icon {
  position: relative;
}

.badge-shopping-car {
  position: absolute;
  top: -7px;
  right: -15px;
}
