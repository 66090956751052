@import './../colors.scss';
@import './../variables.scss';
@import './../breakpoints.scss';

#login {
  min-height: 100vh;
  background-color: $orange;
}

.mobile-logo-container {
  margin-bottom: 65px;
}

.version-dev {
  position: absolute;
  bottom: 5px;
}

@include media-breakpoint-up(md) {
  .presentation-image-container {
    overflow-y: hidden;
    // clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    height: $whole-page-height;
  }

  .cropped-presentation-image {
    padding-bottom: 10px;
    max-height: $whole-page-height;
    width: 100%;
    height: auto;
  }

  .presentation-image-container {
    position: relative;
  }

  .logo_sac_home {
    position: absolute;
    left: 20%;
    top: 30px;
  }
  #login {
    min-height: $whole-page-height;
    background-color: white;
  }
}
