@import './../colors.scss';
@import './../breakpoints.scss';

.upload-document-container {
  padding: 5px 10px;
  border: 1px solid $gray-sac1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
}

.document-options {
  min-width: 100px;
  .document-option {
    margin-left: 10px;
    height: 15px;
  }
}

.document-name {
  flex-grow: 1;
}

@include media-breakpoint-up('md') {
  .upload-document-container {
    padding: 0 10px;
    border: none;
  }

  .document-name {
    text-align: right;
    margin-right: 20px;
  }

  .document-options {
    min-width: 140px;
    .document-option {
      height: 25px;
    }
  }
}
