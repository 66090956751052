@import './../colors.scss';

.no-content {
  margin-top: 10%;
  color: $gray-sac3;
}
.no-content-icon {
  font-size: 100px;
}
.no-content-text {
  margin-top: 30px;
  font-weight: bold;
}
