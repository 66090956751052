@import './../colors.scss';

.formative-fields-container {
  border: 1px solid $gray-sac1;
  border-radius: 10px;
  padding: 15px;
}

.description-path {
  font-size: 14px;
}

.img-grade-container.active img {
  padding-bottom: 5px;
  border-bottom: 3px solid $orange;
}

.status-grades {
  row-gap: 16px;
}

.evaluation-time {
  width: 40px;
  height: 40px;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.evaluation-time.active {
  background-color: $orange;
  font-weight: 500;
  color: white;
}

.evaluation-time:hover {
  background-color: $blue;
  color: white;
  transform: scale(1.1);
}
