@import './../colors.scss';
@import './../breakpoints.scss';

.becas-img-cover-container {
  margin-top: -1.5rem !important;
  position: relative;
  height: 250px;
  overflow: hidden;
}

.becas-img-cover {
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.schoolarship-description {
  font-size: 12px;
}

@include media-breakpoint-up('md') {
  .becas-img-cover-container {
    margin-top: 20px !important;
    height: 300px;
    overflow-y: hidden;
  }
}
