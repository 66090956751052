@import '../colors.scss';

.normateca {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.normateca-container {
  flex-grow: 1;
}

.software-container {
  background-color: #4d3c99;
}
