@import './../colors.scss';
@import './../breakpoints.scss';

.order-detail {
  background-color: #edf8ff;
  margin-top: -1.5rem;
  padding: 20px;
}

.order-products {
  max-width: 1290px;
}

@include media-breakpoint-up('md') {
  .order-detail {
    margin-top: 0;
  }

  .order-products {
    padding: 20px;
  }
}
