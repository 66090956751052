.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
