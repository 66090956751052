.logo-institution {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 190px;
  & .logo-institution-container {
    height: 100%;
    width: 100%;
    padding: 5px;
    text-align: center;
    overflow: hidden;
    position: relative;
    & .logo-institution-img {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
