@import './../colors.scss';
@import './../breakpoints.scss';

.photo-container {
  text-align: center;
  border-radius: 10px;
  & .photo {
    display: inline-block;
    background-color: $gray-sac2;
    border-radius: 10px;
    width: 130px;
    padding: 5px;
    & img {
      width: 100%;
      height: auto;
    }
    & .photo-footer {
      color: white;
      font-size: 10px;
      font-weight: bold;
      padding: 4px 0;
      background-color: $orange;
      border-radius: 0 0 10px 10px;
      margin: 5px -5px -5px -5px;
    }
  }
}

.photo-instructions {
  width: 50%;
  & span {
    display: block;
    color: #969696;
    font-size: 10px;
  }
}

@include media-breakpoint-up('md') {
  .photo-instructions {
    width: 80%;
  }
}
