@import './../colors.scss';
@import './../breakpoints.scss';

.board-main-link {
  position: relative;
}

.board-main-link-container {
  border-radius: 15px;
  padding: 15px 15px 15px 15%;
  margin-bottom: 20px;
  text-decoration: none;
}

.board-main-icon-container {
  min-width: 70px;
}

.board-main-title {
  margin-left: 50px;
  color: white;
  font-size: 20px;
}

@include media-breakpoint-up(md) {
  .board-main-link-container {
    flex-direction: column;
    padding: 15px;
    justify-content: center;
    align-items: center;
    min-height: 170px;
  }

  .board-main-title {
    text-align: center;
    margin: 0;
    margin-top: 5px;
  }
}

@include media-breakpoint-up(lg) {
  .board-main-link-container {
    flex-direction: row;
    padding: 15px 30px;
    justify-content: start;
    align-items: center;
    min-height: 170px;
  }

  .board-main-title {
    text-align: left;
    margin: 20px;
  }
}
