@import './../colors.scss';
@import './../breakpoints.scss';

.store {
  background-color: #edf8ff;
  margin-top: -1.5rem;
}

.store-products {
  max-width: 1200px;
  margin: 10px auto;
}

.store-products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 10px;
  padding: 10px;
  justify-content: space-between;
  // max-width: 1200px;
}

@include media-breakpoint-up('md') {
  .store {
    margin-top: 0;
  }
}
