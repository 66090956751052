@import './../colors.scss';
@import './../breakpoints.scss';

.form-login {
  padding-top: 60px;
  padding-bottom: 60px;
}

.login-link-header {
  color: $blue;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.5);
  }
}

.login-form-container {
  & .txt-usuario {
    margin-bottom: 20px;
  }

  & .txt-pass {
    margin-bottom: 50px;
    position: relative;

    & .input-login {
      padding-right: 32px;
    }

    & .show-password {
      position: absolute;
      height: 100%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      cursor: pointer;
      color: $blue;
      max-height: 37px;
    }
  }
}

.btn-captcha {
  background-color: transparent;
  border: 2px solid #000000;
  height: 37px;
  margin-bottom: 40px;

  & i {
    font-size: 14px;
  }
}

.btn-login {
  width: 110px;
  margin-bottom: 40px;
  background-color: $orange;
  color: white;
  border: 1px solid white;
  // transition: all 0.5s ease;
  &:hover {
    // transform: scale(1.1);
    color: white;
  }
}

.titles-login,
.forgot-password-link {
  color: white;
  transition: all 0.5s ease-in-out;
}

.forgot-password-link:hover {
  font-weight: bold;
  text-decoration: underline;
}

.error-inputform-message {
  color: white;
}

@include media-breakpoint-up(md) {
  .btn-login {
    border: none;
  }
  .titles-login {
    color: $blue;
  }
  .forgot-password-link {
    color: $orange;
  }
  .error-inputform-message {
    color: $orange;
  }
}
