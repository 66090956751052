@import '../breakpoints.scss';
@import '../colors.scss';

.react-calendar {
  font-size: 13px;
}

.react-calendar__navigation__label__labelText {
  font-size: 14px;
  font-weight: bold;
}
.calendar-icon-container {
  margin-right: 16px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  color: $orange;
}
