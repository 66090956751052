@import './../colors.scss';
@import './../breakpoints.scss';

.order-status {
  font-size: 12px;
}

.order-link {
  text-decoration: none;
}
