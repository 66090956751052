@import './../breakpoints.scss';
@import './../colors.scss';

$max-width-calendar: 400px;
$height-header-calendar: 45px;
$height-calendar: 330px;

// TEMPORAL: FASE 1
.react-calendar__navigation {
  pointer-events: none;
}
// TEMPORAL: FASE 1

.react-calendar {
  border: none;
  background-color: #f2f2f2;
  width: 100%;
  height: $height-header-calendar;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  & .react-calendar__navigation {
    height: $height-header-calendar;
  }

  & .react-calendar__viewContainer {
    display: none;
  }

  & .react-calendar__month-view__weekdays {
    background-color: #ebebeb;
    height: 36px;
    line-height: 20px;
  }

  & .react-calendar__navigation {
    background-color: $orange;
    border: none;
    margin-bottom: 0;
  }

  & .react-calendar__navigation button {
    color: white;
  }

  & .react-calendar__month-view__days {
    background-color: #ebebeb;
  }

  & .react-calendar__month-view__days__day--weekend {
    color: black;
  }

  & .react-calendar__month-view__days__day--neighboringMonth {
    color: #aaaaaa;
  }

  & .react-calendar__tile--active,
  & .react-calendar__tile--active:enabled:hover,
  & .react-calendar__tile--active:enabled:focus {
    background-color: transparent;
    border: 1px solid $orange;
    color: black;
  }

  & .react-calendar__tile--now,
  & .react-calendar__tile--now:enabled:hover,
  & .react-calendar__tile--now:enabled:focus {
    background-color: $orange;
    color: white;
  }

  .react-calendar__tile.react-calendar__month-view__days__day:hover {
    background-color: $gray-sac3;
  }

  & .react-calendar__tile {
    height: calc(($height-calendar - 80px) / 5);
  }
}

@include media-breakpoint-up(md) {
  // TEMPORAL: FASE 1
  .react-calendar__navigation {
    pointer-events: auto;
  }
  // TEMPORAL: FASE 1
  .react-calendar {
    height: $height-calendar;
    max-width: $max-width-calendar;

    & .react-calendar__navigation {
      height: 45px;
      border-radius: 0;
    }

    & .react-calendar__viewContainer {
      display: flex;
    }
  }
}
