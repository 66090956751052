@import './../colors.scss';
@import './../breakpoints.scss';

.formative-field-header,
.types-of-formative-fields {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.types-of-formative-fields {
  column-gap: 16px;
}

.formative-field:not(:last-child) {
  border-bottom: 1px solid $gray-sac1;
}

.formative-field-title-container {
  flex-grow: 1;
}

.formative-field-type {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 8px;
  background-color: #e8e8e8;
  font-weight: bold;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
  color: $blue;
}

.formative-field-type.formative-field-C {
  background-color: $orange;
  color: white;
}
.formative-field-type.formative-field-EC {
  background-color: $blue;
  color: white;
}

.formative-field-evaluation-open {
  padding: 4px;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-top: 16px;
}

.formative-field-evaluation-open.hide {
  display: none;
}
