.container-board {
  max-width: 1200px !important;
}
.calendar-section {
  display: flex;
  flex-direction: column;
}
.board-calendar {
  flex-grow: 1;
}
