@import './../colors.scss';

.header-title-menu {
  margin-right: 15px;
}

.header-mobile-title-container {
  height: 60px;
  padding: 0 30px;
  background-color: $blue;
  color: white;
}

.header-mobile-option {
  text-align: center;
  background-color: #e8e8e8;
  padding: 3px 10px;
}
.header-mobile-option:first-child {
  border-right: 2px solid white;
}
.header-mobile-option:last-child {
  border-left: 2px solid white;
}
.header-mobile-option > div {
  margin: 0;
  text-align: center;
}

.header-mobile-option .user-img-container {
  margin: 0 auto;
}
