@import '../colors.scss';

.software-link {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  padding: 8px;
  margin: 8px 16px;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.software-link-img {
  width: 100%;
}
