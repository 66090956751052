@import '../colors.scss';

.counter-loaded-documents-label {
  color: $orange;
  font-weight: bold;
}
.counter-loaded-documents {
  margin-left: 16px;
  padding: 2px 16px;
  background-color: #e8e8e8;
  border-radius: 8px;

  & .loaded {
    color: $blue;
    font-size: 18px;
  }

  & .total {
    color: $blue;
    font-size: 22px;
    font-weight: bold;
  }
}
