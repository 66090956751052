@import './../variables.scss';
@import './../colors.scss';

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  background-color: $orange;
  padding: 10px 30px;

  & .logo-footer {
    width: 88px;
    height: auto;
  }

  .privacy-notice-text {
    &:hover {
      color: $blue;
    }
  }
}
