@import './../colors.scss';
@import './../breakpoints.scss';

.no-products {
  color: $gray-sac3;
  .no-products-icon {
    font-size: 100px;
    margin: 20px 0;
  }
}

.table-products {
  font-size: 13px;

  thead th {
    background-color: $blue;
    color: white;
    min-width: 130px;
    padding: 5px 15px;
    font-weight: bold;
    display: none;
  }
  .thead-order-options {
    min-width: 20px;
  }
  tbody td {
    background-color: transparent;
    padding: 5px;
  }
  tfoot td {
    display: none;
  }
  .td-img-product .img-product {
    width: 100%;
    max-width: 100px;
    height: auto;
  }
}

.order-options {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .btn-pay {
    border-radius: 0 !important;
    display: block;
    width: 100%;
    height: 60px;
  }
}

.total-mobile {
  min-height: 35px;
}

@include media-breakpoint-up('md') {
  .table-products {
    thead th {
      display: table-cell;
    }
    tbody td {
      background-color: white;
      border: 1px solid $gray-sac3;
    }
    tfoot td {
      display: table-cell;
    }
  }

  .order-options {
    position: relative;
    width: auto;
    .btn.btn-pay {
      width: auto;
      min-width: 100px;
      height: auto;
      font-size: 12px;
    }
  }
}
