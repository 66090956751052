@import './../colors.scss';
@import './../mixins.scss';
@import './../breakpoints.scss';

$lines-to-show: 2;

.task-item {
  border-top: 1px solid $gray-sac1;
  border-bottom: 1px solid $gray-sac1;
  &.active {
    background-color: $gray-sac2;
  }
}

.task-item-container {
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas:
    'ga_image ga_date ga_options'
    'ga_image ga_author ga_options'
    'ga_description ga_description ga_options';
  margin-bottom: 3px;
}

.ga_image {
  grid-area: ga_image;
}

.ga_date {
  grid-area: ga_date;
}

.ga_options {
  grid-area: ga_options;
}

.ga_author {
  grid-area: ga_author;
}

.ga_description {
  grid-area: ga_description;
}

.ga_image,
.ga_date,
.ga_options,
.ga_author,
.ga_description {
  padding: 5px;
  align-self: center;
}

.ga_date {
  padding-bottom: 0;
}

.ga_author {
  padding-top: 0;
}

.ga_description {
  padding-left: 20px;
  padding-right: 20px;
}

.task-image-container {
  justify-self: center;
}

.task-image {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 8px;
  & img {
    height: 100%;
    width: auto;
  }
}

.task-options-container {
  height: 100%;
}

.task-options {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.task-option {
  margin: 0 8px;
}

.task-description {
  font-size: 10px;
  font-weight: 600;
  @include break-text($lines-to-show);
}

.task-author {
  font-size: 10px;
}

.task-date {
  font-size: 16px;
}

@include media-breakpoint-up('md') {
  .task-item {
    border: 2px solid $gray-sac1;
    border-radius: 8px;
    margin-bottom: 3px;
    padding: 10px 5px;
  }
  .task-item-container {
    grid-template-columns: 80px 120px 1fr 120px;
    grid-template-areas:
      'ga_date ga_image ga_author ga_options'
      'ga_date ga_image ga_description ga_options'
      'ga_date ga_image ga_description ga_options';
  }

  .task-image {
    width: 50px;
    height: 50px;
  }

  .task-options {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .ga_image,
  .ga_date,
  .ga_options,
  .ga_author,
  .ga_description {
    padding: 0;
  }

  .ga_author {
    align-self: end;
    padding-bottom: 3px;
  }

  .ga_description {
    align-self: stretch;
    padding-bottom: 3px;
  }
}
