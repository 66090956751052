@import './../colors.scss';

.main-notice-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  border: 2px solid $gray-sac1;
  border-radius: 10px;
  padding: 10px 15px;
}

.main-notice-icon {
  &.notice {
    color: $orange;
  }
  &.remainder {
    color: $teal;
  }
}

.main-notice-icon svg {
  height: 60px;
}

.main-notice-text {
  margin-left: 15px;
}
