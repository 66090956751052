@import './../colors.scss';
@import './../breakpoints.scss';

$height: 30px;

@include media-breakpoint-up(md) {
  header {
    height: 65px;
    border-bottom: 2px solid $gray-sac2;
  }

  .header-options-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0;
    align-items: center;
  }

  .header-icon-container {
    height: $height;
    & .header-icon {
      width: auto;
      height: $height;
    }
  }
}

@include media-breakpoint-up(lg) {
  .header-options-container {
    padding-right: 65px;
  }
}
