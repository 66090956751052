@import '../colors.scss';

.procedure {
  padding: 8px;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  .procedure-header,
  .procedure-body {
    border: 2px solid $blue;
    border-bottom: none;
  }

  .procedure-header {
    border-radius: 8px 8px 0 0;
  }
  .procedure-body {
    border-top: none;
    padding: 8px;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .procedure-footer {
    border-radius: 0 0 8px 8px;
    padding: 8px;
    text-decoration: none;
  }
}
