@import './../colors.scss';
@import './../mixins.scss';

$lines-to-show: 3;

.board-message {
  border: 3px solid $orange;
  border-radius: 15px;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.board-message-detail-container {
  padding: 0 15px;
  flex-grow: 1;
}

.board-message-detail {
  color: $gray-sac3;
  @include break-text($lines-to-show);
}

.board-message-action {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: bold;
  min-width: 85px;
}

.board-message-icon-container {
  border-radius: 8px;
  padding: 10px;
  color: white;

  & svg {
    width: 50px;
    height: 50px;
  }
}
