@import './../colors.scss';
@import './../breakpoints.scss';

.schoolarships-options-container {
  flex-direction: column;
}

.schoolarship-option {
  color: $blue;
  font-family: 'Arvo';
  border: 2px solid $blue;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: white;
    background-color: $blue;
    transform: scale(1.1);
  }

  svg {
    margin-right: 15px;
    margin-left: 50px;
  }
}

.schoolarship-description {
  display: none;
  font-family: 'Verdana';
  text-align: center;
  margin-top: 15px;
}

@include media-breakpoint-up('md') {
  .schoolarships-options-container {
    flex-direction: row;
    justify-content: space-around;
  }
  .schoolarship-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 175px;
    svg {
      margin: 0;
      margin-bottom: 15px;
    }
  }
  .schoolarship-description {
    display: block;
  }
}
