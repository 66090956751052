@import '../colors.scss';

.sync {
  margin-right: 16px;
}

.sync-icon {
  font-size: 25px;
  color: $teal;
}
