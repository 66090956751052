@import '../colors.scss';
@import '../breakpoints.scss';

.procedure-status-row {
  display: flex;
  flex-direction: row;
  margin: 0 4px;
  font-size: 13px;
  & > [class*='col'] {
    padding: 8px 16px;
    border: 1px solid $blue;
    overflow: hidden;
  }
  .col-header {
    font-family: 'Arvo';
    color: $blue;
  }
}

@include media-breakpoint-up('md') {
  .procedure-status-row {
    font-size: 16px;
  }
}
