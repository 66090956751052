@import '../colors.scss';

.notifications-not-readed {
  display: inline-block;
  position: absolute;

  & .notifications-not-readed-container {
    background-color: #e74c3c;
    padding: 0 6px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    font-size: 11px;
  }
}
