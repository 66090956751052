@import '../colors.scss';
@import '../breakpoints.scss';

.file-viewer {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 3;
  max-height: 100%;
  overflow: auto;
}

.file-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
  text-align: center;

  embed {
    padding: 15px;
  }
}

.go-back {
  display: flex;
  background-color: $orange;
  border-radius: 50%;
  margin: 5px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 5;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.btn-download {
  display: flex;
  background-color: $green;
  border-radius: 50%;
  margin: 5px;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  right: 80px;
  z-index: 5;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.pdf-viewer {
  background-color: #d2d2d2 !important;
  min-width: 100%;
  padding-bottom: 0 !important;
  text-align: center;
  color: black;
  font-size: 18px;
}

.pdf-paginator {
  padding: 8px 0 !important;
  background-color: black !important;
}

@include media-breakpoint-up('md') {
  .file-viewer-container {
    width: 80%;
  }
} ;
