@import './../colors.scss';
@import './../variables.scss';
@import './../breakpoints.scss';

.side-menu {
  background-color: $gray-sac3;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;
  &.open {
    width: $width-open-side-menu;
    background-color: $gray-sac1;
  }
}

.side-menu-home,
.side-menu-logout {
  align-self: flex-end;
  padding: 10px 20%;
  color: white;
  display: flex;
  align-items: center;
}

.side-menu-logout {
  background-color: $teal;
}

.side-menu-home-label,
.side-menu-logout-label {
  text-decoration: none;
  margin-left: 30px;
}

@include media-breakpoint-up(md) {
  .side-menu {
    position: absolute;
    left: 0;
    top: 0;
    width: $width-closed-side-menu;
  }
}
