@import './../colors.scss';
@import './../breakpoints.scss';

.user-menu {
  position: relative;
}

.user-img-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 35px 0 0;
  color: $teal;
}

.users-list-container {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 46px;
  width: 335px;
  background-color: $gray-sac1;
}

.user-img {
  background-color: white;
  width: 30px;
  height: 30px;

  border-radius: 50%;
}

@include media-breakpoint-down('md') {
  .users-list-container {
    right: initial;
    left: 0;
    top: 34px;
  }
}
