// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #aaa8a8 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

@import './colors.scss';

// scss-docs-start colors-map
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $head-foot !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;

@import './breakpoints.scss';

$i: 5;
@while $i <= 100 {
  .w-#{$i} {
    width: #{'#{$i}%'} !important;
  }
  $i: $i + 5;
}

@each $bp-name, $bp-pixels in $grid-breakpoints {
  $i: 5;
  @while $i <= 100 {
    @media (min-width: $bp-pixels) {
      .w-#{$bp-name}-#{$i} {
        width: #{'#{$i}%'} !important;
      }
    }
    $i: $i + 5;
  }
}

// Borders
$i: 0;
@while $i <= 3 {
  .b-#{$i} {
    border-width: #{'#{$i}px'} !important;
  }
  $i: $i + 1;
}

@each $bp-name, $bp-pixels in $grid-breakpoints {
  $i: 0;
  @while $i <= 3 {
    @media (min-width: $bp-pixels) {
      .b-#{$bp-name}-#{$i} {
        border-width: #{'#{$i}px'} !important;
      }
    }
    $i: $i + 1;
  }
}

// Fonts
$i: 8;
@while $i <= 100 {
  .f-#{$i}px {
    font-size: #{'#{$i}px'} !important;
  }
  $i: $i + 1;
}

@import '~bootstrap/scss/bootstrap';
