@import '../colors.scss';

.schoolarship-type {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
}

.schoolarship-type:nth-child(2n) {
  background-color: rgb(255, 120, 78, 0.15);
}

.schoolarship-type-title {
  font-weight: bold;
  color: $orange;
}

.schoolarship-type-title,
.schoolarship-type-description {
  text-align: left;
}

.start-process-schoolarship {
  padding: 4px 8px;
  border-radius: 5px;
  text-decoration: none;
}
