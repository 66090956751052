@import './../colors.scss';

.side-menu-item {
  padding: 10px;
  padding-left: 30px;
  color: #20164d;
  font-family: 'Arvo';
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: $orange;
    color: white;
  }
}

.side-menu-item > * {
  display: block;
}

.side-menu-item .svg-icon-from-text {
  width: 20%;
  & svg {
    width: 100%;
    height: auto;
  }
}

.side-menu-item > span {
  margin-left: 20px;
}
