@mixin highlight-input($color) {
  border: 1px solid $color;
  box-shadow: 0 0 3px $color;
  outline: none;
  // outline-color: $color;
}

@mixin break-text($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}
