@import './../colors.scss';

.connection-indicator-container {
  position: relative;
  border-radius: 12px;
  background-color: $gray-sac1;
  font-size: 9px;
  color: $gray-sac3;
  padding: 3px 2px 3px 30px;
  height: 24px;
  width: 85px;
  display: inline-flex;
  align-items: center;
  transition: all 0.5s ease;

  .connection-indicator-circle {
    position: absolute;
    right: initial;
    left: 3px;
    top: 3px;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    transition: all 0.5s ease;
  }

  &.active {
    background-color: #007bff;
    color: white;
    padding: 3px 2px 3px 15px;

    & .connection-indicator-circle {
      position: absolute;
      left: initial;
      right: 3px;
      top: 3px;
    }
  }
}
