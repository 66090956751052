@import './../colors.scss';
@import './../breakpoints.scss';

.product-container {
  background-color: white;
}

.product-details,
.product-options {
  background-color: $gray-sac2;
  padding: 10px;
}

.product-options {
  border-radius: 0 0 5px 5px;
  & button {
    font-size: 10px !important;
  }
}

.product-price {
  color: #4d3c99;
  font-weight: bold;
  min-width: 50px;
  text-align: right;
}

.product-img-container {
  height: 150px;
  overflow: hidden;
  text-align: center;

  img {
    width: auto;
    height: 100%;
  }
}
