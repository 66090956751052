@import './../colors.scss';

$image-size: 35px;

.student-item {
  padding: 12px;
  &:hover,
  &.active {
    background-color: $orange;
    & .student-name {
      color: white !important;
    }
    .student-image-container {
      color: white;
    }
  }

  .student-image-container {
    color: $teal;
    width: $image-size;
    height: $image-size;
    border-radius: $image-size/2;
    overflow: hidden;
    margin-right: 12px;
  }

  .student-image {
    width: $image-size;
    height: auto;
    border-radius: $image-size/2;
  }
}
